import React, { useMemo, useState } from 'react'
import dayjs from 'dayjs'
import {
  Button, Typography, Radio,
  Space, RadioChangeEvent, Row, Col,
  notification,
} from 'antd'
import useAccountReport from 'hooks/useAccountReport'
import { AccountPublic, PlanType } from '@pollination-solutions/pollination-sdk'

interface UsageReportProps {
  // Add props here
  account: AccountPublic
  licenceType: PlanType
}

const reportTypeMap = {
  [PlanType.CloudCompute]: {
    title: 'Compute',
    description: 'Compute usage report for the selected billing period.',
    useDateRange: true,
  },
  [PlanType.Seat]: {
    title: 'Members',
    description: 'Organization members report (includes past members).',
    useDateRange: false,
  },
  [PlanType.RhinoPlugin]: {
    title: 'Rhino Plugin',
    description: 'Rhino plugin usage report for the selected billing period.',
    productName: 'rhino_plugin',
    useDateRange: true,
  },
  [PlanType.RevitPlugin]: {
    title: 'Revit Plugin',
    description: 'Revit plugin usage report for the selected billing period.',
    productName: 'revit_plugin',
    useDateRange: true,
  },
  [PlanType.BundledPlugin]: {
    title: 'Revit and Rhino Plugin',
    description: 'Revit and Rhino plugin usage report for the selected billing period.',
    useDateRange: true,
  }
}

const getPastDate = (days: number) => {
  const today = new Date()
  return new Date(today.setDate(today.getDate() - days))
}


const UsageReport = (props: UsageReportProps) => {

  const [reportDays, setReportDays] = useState(30)
  const [downloading, setDownloading] = useState(false)
  const { getReport, reportToCsv, triggerDownload } = useAccountReport(props.account)

  const today = new Date()

  const pastDate = {
    7: getPastDate(7),
    30: getPastDate(30),
    90: getPastDate(90),
    180: getPastDate(180)
  }

  const fromDate = useMemo(() => {
    return pastDate[reportDays]
  }, [reportDays])

  const handleOk = () => {
    setDownloading(true)
    getReport(fromDate, today, props.licenceType)
      .then(({ data }) => {
        const csv = reportToCsv(data)
        triggerDownload(`${props.licenceType}-report.csv`, csv)
        console.log(csv)
      }).catch((err) => {
        notification.error({
          message: err.response.data.detail,
        })
      }).finally(() => {
        setDownloading(false)
      })
  }

  const onChange = (e: RadioChangeEvent) => {
    setReportDays(e.target.value)
  }

  return (
    <>
      <Row gutter={[0, 12]}>
        <Col span={24}>
          <Typography.Paragraph>
            {reportTypeMap[props.licenceType].description}
          </Typography.Paragraph>
        </Col>
        {reportTypeMap[props.licenceType].useDateRange && (
          <Radio.Group onChange={onChange} value={reportDays}>
            <Space direction="vertical">
              <Radio value={7}>
                <Space>
                  <Typography.Text strong>
                    Last 7 days
                  </Typography.Text>
                  <Typography.Text type="secondary">(
                    {dayjs(pastDate[7]).format('D MMM YYYY')} -
                    {dayjs(today).format('D MMM YYYY')}
                    )
                  </Typography.Text>
                </Space>
              </Radio>
              <Radio value={30}>
                <Space>
                  <Typography.Text strong>
                    Last 30 days
                  </Typography.Text>
                  <Typography.Text type="secondary">(
                    {dayjs(pastDate[30]).format('D MMM YYYY')} -
                    {dayjs(today).format('D MMM YYYY')}
                    )
                  </Typography.Text>
                </Space>
              </Radio>
              <Radio value={90}>
                <Space>
                  <Typography.Text strong>
                    Last 90 days
                  </Typography.Text>
                  <Typography.Text type="secondary">(
                    {dayjs(pastDate[90]).format('D MMM YYYY')} -
                    {dayjs(today).format('D MMM YYYY')}
                    )
                  </Typography.Text>
                </Space>
              </Radio>
              <Radio value={180}>
                <Space>
                  <Typography.Text strong>
                    Last 180 days
                  </Typography.Text>
                  <Typography.Text type="secondary">(
                    {dayjs(pastDate[180]).format('D MMM YYYY')} -
                    {dayjs(today).format('D MMM YYYY')}
                    )
                  </Typography.Text>
                </Space>
              </Radio>
            </Space>
          </Radio.Group>
        )}
        <Col span={24}>
          <Button type="primary" onClick={handleOk} loading={downloading}>
            Download
          </Button>
        </Col>
      </Row>
    </>
  )
}

export default UsageReport