import React, { useState, useMemo, useEffect, useCallback } from 'react'

import { Subscription, PlanType, QuotaPlan, SubscriptionPlan, BillingOption, SubscriptionUpdate } from '@pollination-solutions/pollination-sdk'
import { Space, InputNumber, Button, Typography, Modal, Skeleton, Select, List, Col, Row, Divider, Input, notification, Alert } from 'antd'
import numbro from 'numbro'
import { InputLabel } from 'atoms'
import { getLabel } from 'hooks/useQuotas'
import { ThunderboltFilled } from '@ant-design/icons'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import dayjs from 'dayjs'
import { useAuth } from 'auth-context'


const calculateQuotaMultiplier = (quota: QuotaPlan, quantity: number): number | undefined => {
  if (quota.limit) {
    const val = quota.limit * quantity
    if (quota.max_limit) {
      return Math.min(val, quota.max_limit)
    }
    return val
  }
  return
}

const subscriptionWithQuantity = (subscription: Subscription): boolean => {
  switch (subscription.type) {
    case PlanType.Cloud:
      return false
    default:
      return true
  }
}

const priceText = (price: number, currency: string) => {
  const ammount = numbro(price).formatCurrency({
    thousandSeparated: true,
    mantissa: 2
  })
  return `${ammount} ${currency}`
}

interface ManageSubscriptionFormProps {
  subscription: Subscription
  plans: SubscriptionPlan[]
  paidByInvoice: boolean
  updateSubscription: (update: SubscriptionUpdate) => Promise<void>
  cancelSubscription: () => Promise<void>
  loading: boolean
}

const billingTypeDisplay = {
  'month': 'Monthly',
  'year': 'Yearly',
}

export const ManageSubscriptionForm: React.FunctionComponent<ManageSubscriptionFormProps> = ({
  subscription, plans, paidByInvoice, loading,
  updateSubscription, cancelSubscription

}) => {

  const currentSubscriptionPlan = useMemo(() =>
    plans.filter(p => p.slug == subscription.plan_slug)[0] ?? plans[0],
    [subscription, plans]
  )

  const { client } = useAuth()

  const [plan, setPlan] = useState(currentSubscriptionPlan)
  const [billingPeriod, setBillingPeriod] = useState<BillingOption>()
  const [currency, setCurrency] = useState<string>('USD')
  const [quantity, setQuantity] = useState<number>(subscription.plan_multiplier ?? 1)

  const [updateModal, setUpdateModal] = useState(false)
  const [updateButtonLoading, setUpdateButtonLoading] = useState(false)

  const [cancelModal, setCancelModal] = useState(false)
  const [cancelButtonLoading, setCancelButtonLoading] = useState(false)

  const { xs } = useBreakpoint()

  // TODO: This is really annoying and could cause problems later
  // there should be a better connection between subscription and plan
  const subscriptionPeriod = useMemo(() => {
    if (!subscription.billing_info) return 'month'
    const { last_payment, next_payment } = subscription.billing_info
    if (!last_payment || !next_payment) return 'month'
    const lastPaymentDate = dayjs(last_payment.date)
    const nextPaymentDate = dayjs(next_payment.date)
    const period = nextPaymentDate.diff(lastPaymentDate, 'month')
    return period > 1 ? 'year' : 'month'
  }, [subscription])


  const billingOptions = useMemo(() => {
    if (plan && plan.billing_options) {
      return plan.billing_options
    } else {
      return []
    }
  }, [plan])

  const subscriptionWillChange = useMemo(() => {

    if (billingPeriod && (subscriptionPeriod != billingPeriod?.billing_type)) {
      return true
    }
    if (subscription.plan_slug != plan.slug) {
      return true
    }
    if (subscription.plan_multiplier != quantity) {
      return true
    }
    return false
  }, [subscriptionPeriod, subscription, plan, billingPeriod, quantity])

  const canCancel = useMemo(() => {
    return !subscriptionWillChange && billingPeriod
  }, [subscriptionWillChange, billingPeriod])

  const unitCost = useMemo(() => {
    if (!billingPeriod || !billingPeriod.recurring_price[currency]) {
      return 0
    }

    return billingPeriod.recurring_price[currency]
  }, [billingPeriod, currency])

  const totalCostText = useMemo(() => {
    return priceText(unitCost * quantity, currency)
  }, [unitCost, currency, quantity])

  const currentSubscriptionCostText = useMemo(() => {
    if (subscription.billing_info) {
      return priceText(subscription.billing_info.last_payment.amount, subscription.billing_info.last_payment.currency)
    }
    return priceText(0, currency)
  }, [subscription, currency])

  useEffect(() => {
    if (plan && plan.billing_options && plan.billing_options.length > 0) {
      const index = Math.max(plan.billing_options.findIndex(o => o.billing_type === subscriptionPeriod), 0)
      setBillingPeriod(plan.billing_options[index])
    } else {
      setBillingPeriod(undefined)
    }
  }, [plan, subscriptionPeriod])

  const cancelSubscriptionWrapper = useCallback(() => {
    if (plan.type === PlanType.CloudCompute) {
      return client.projects.listProjects({
        owner: [subscription.owner.name],
        _public: false,
        // @ts-ignore
      }).then(({ data }) => {
        if (data.resources.length === 0) {
          notification.info({
            message: 'No private projects to delete',
          })
          return Promise.resolve()
        }
        notification.info({
          message: `Deleting ${data.resources.length} private projects`,
          description: 'This may take up to a minute'
        })

        return Promise.all(data.resources.map((project) => {
          return client.projects.deleteProject({ owner: project.owner.name, name: project.name })
        }))
      }).catch((e) => {
        notification.error({
          message: 'Failed to delete private projects',
          description: e.message
        })
      }).finally(() => {
        return cancelSubscription()
      })
    }
    return cancelSubscription()
  }, [plan, client, cancelSubscription, subscription])

  const additionalMessage = useMemo(() => {
    if (plan.type === PlanType.CloudCompute) {
      return 'If you have any Private Projects they will be deleted when you cancel your subscription along with all associated data.'
    }
    return null
  }, [plan])


  return (
    <>
      {loading ?
        <Skeleton active />
        :
        <Row justify='space-between'>
          <Col span={xs ? 24 : 16}>
            {paidByInvoice ? (
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Typography.Text type='secondary'>This subscription is paid by invoice.</Typography.Text>
                </Col>
                <Col span={24}>
                  <Typography.Text type='secondary'>Contact your administrator to request changes to this subscription.</Typography.Text>
                </Col>
                <Col>
                  <InputLabel label='Plan' active={false} style={{ paddingLeft: 0, paddingRight: 0 }}>
                    <Input
                      disabled
                      value={plan.slug}
                    />
                  </InputLabel>
                </Col>
                {
                  subscriptionWithQuantity(subscription) && (
                    <Col>
                      <InputLabel label='Quantity' active={false} style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <Input
                          disabled
                          value={quantity}
                        />
                      </InputLabel>
                    </Col>
                  )
                }
              </Row>
            ) : (
              <Row gutter={[16, 16]} justify='space-between'>
                <Col span={24}>
                  <Row gutter={16}>
                    <Col>
                      <InputLabel label='Plan' style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <Select
                          dropdownMatchSelectWidth={false}
                          value={plan.slug}
                          onSelect={(v) => {
                            const selectedPlan = plans.filter((p) => p.slug == v).pop()
                            if (selectedPlan) {
                              setPlan(selectedPlan)
                              setQuantity(1)
                            }
                          }}
                        >
                          {
                            plans.map((p, i) => (
                              <Select.Option key={i} value={p.slug}>{p.name}</Select.Option>
                            ))
                          }
                        </Select>
                      </InputLabel>
                    </Col>
                    {
                      subscriptionWithQuantity(subscription) && (
                        <Col>
                          <InputLabel label='Quantity' style={{ paddingLeft: 0, paddingRight: 0 }}>
                            <InputNumber
                              min={1} max={100000} defaultValue={1} value={quantity}
                              onChange={(v) => setQuantity(v as number)} />
                          </InputLabel>
                        </Col>

                      )
                    }
                    {
                      billingOptions.length !== 0 && (
                        <Col>
                          <InputLabel label='Billing Period' style={{ paddingLeft: 0, paddingRight: 0 }}>
                            <Select value={billingPeriod?.billing_type ?? billingOptions[0].billing_type}
                              onSelect={(value) => {
                                setBillingPeriod(billingOptions.find((option) => option.billing_type === value))
                              }}
                            >
                              {billingOptions.map((b, i) => (
                                <Select.Option key={i} value={b.billing_type}>{billingTypeDisplay[b.billing_type]}</Select.Option>
                              ))}
                            </Select>
                          </InputLabel>
                        </Col>
                      )
                    }
                  </Row>
                </Col>
                <Col span={24}>
                  <Row justify='start' gutter={16} align='bottom'>
                    <Col>
                      <Button
                        type="primary"
                        style={{
                          maxWidth: 512,
                          alignSelf: 'center'
                        }}
                        disabled={!subscriptionWillChange}
                        onClick={() => {
                          setUpdateModal(true)
                        }}
                      >
                        <ThunderboltFilled />
                        Update
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        style={{
                          maxWidth: 512,
                          alignSelf: 'center'
                        }}
                        disabled={!canCancel}
                        onClick={() => {
                          setCancelModal(true)
                        }}
                      >
                        Cancel
                      </Button>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Space>
                    <Typography.Text strong>{totalCostText}</Typography.Text>
                    <Typography.Text type='secondary'>{billingPeriod ? ` + tax / ${billingPeriod.billing_type}` : ''}</Typography.Text>
                  </Space>
                </Col>
              </Row>
            )}

          </Col>
          <Col span={xs ? 24 : 8}>
            {xs && <Divider />}
            <List size='small'
              dataSource={plan.quotas}
              rowKey={(record) => record.type}
              split={false}
              renderItem={item => {
                const { icon, displayName, format } = getLabel(item.type)
                const limit = calculateQuotaMultiplier(item, quantity)
                return (
                  <List.Item
                    key={item.type}
                    style={{ padding: '6px 0px 4px', justifyContent: xs ? 'flex-start' : 'flex-end' }}
                  >
                    <Space>
                      {xs && icon}
                      <Typography.Text>{limit ? `${format(limit)} ${displayName}` : `Unlimited ${displayName}`}</Typography.Text>
                      {!xs && icon}
                    </Space>
                  </List.Item>
                )
              }}
            />
          </Col>
        </Row>
      }
      <Modal
        title="Update Subscription"
        onOk={() => {
          setUpdateButtonLoading(true)
          if (!billingPeriod) {
            // This means we are selecting a free-tier subscription and should therefore cancel this paying one
            cancelSubscriptionWrapper().finally(() => { setUpdateButtonLoading(false); setUpdateModal(false) })
          } else {
            updateSubscription({
              quantity,
              plan_id: billingPeriod.id
            }).finally(() => { setUpdateButtonLoading(false); setUpdateModal(false) })
          }
        }}
        onCancel={() => setUpdateModal(false)}
        visible={updateModal}
        okButtonProps={{ loading: updateButtonLoading }}
        okText='Confirm'
      >
        {
          subscriptionWithQuantity(subscription) ?
            (
              <Space direction='vertical'>

                <Typography.Text>You current subscription has {subscription.plan_multiplier ?? 1} {currentSubscriptionPlan.name} and costs <strong>{currentSubscriptionCostText}</strong> billed every <strong>{subscriptionPeriod}</strong>.</Typography.Text>
                <Typography.Text>Your new subscription will have <strong>{quantity} {plan.name}</strong> which will cost <strong>{totalCostText}</strong> billed every <strong>{billingPeriod?.billing_type}</strong>.</Typography.Text>
                <Typography.Text>The difference between the two subscriptions will be charged to your card immediately. If the new plan is less expensive, the current payment will be added to your account as a credit and will be deducted from your upcoming payments.</Typography.Text>
                <Typography.Text>Coupons you have applied previously will be removed from your subscription.</Typography.Text>
                <Typography.Link href='mailto:sales@pollination.cloud'>If you need assistance, please contact us.</Typography.Link>
              </Space>
            )
            :
            (
              <Space direction='vertical'>
                <Typography.Text>You current subscription has costs <strong>{currentSubscriptionCostText}</strong>{subscriptionPeriod && (<Typography.Text> billed every <strong>{subscriptionPeriod}</strong></Typography.Text>)}.</Typography.Text>
                <Typography.Text>Your new subscription will cost <strong>{totalCostText}</strong>{billingPeriod && (<Typography.Text> billed every <strong>{billingPeriod?.billing_type}</strong></Typography.Text>)}.</Typography.Text>
                <Typography.Text>The difference between the two subscriptions will be charged to your card immediately. If the new plan is less expensive, the current payment will be added to your account as a credit and will be deducted from your upcoming payments.</Typography.Text>
                <Typography.Text>Coupons you have applied previously will be removed from your subscription.</Typography.Text>
                <Typography.Link href='mailto:sales@pollination.cloud'>If you need assistance, please contact us.</Typography.Link>
              </Space>
            )
        }
      </Modal>
      <Modal
        title="Cancel Subscription"
        onOk={() => { setCancelButtonLoading(true); cancelSubscriptionWrapper().finally(() => { setCancelButtonLoading(false); setCancelModal(false) }) }}
        onCancel={() => setCancelModal(false)}
        visible={cancelModal}
        okButtonProps={{ loading: cancelButtonLoading }}
        okText='Confirm'
      >
        <Space direction='vertical'>
          <Typography.Text>Your subscription will be cancelled effective immediately.</Typography.Text>
          {additionalMessage && <Alert message={additionalMessage} type='warning' showIcon />}
          <Typography.Link href='mailto:sales@pollination.cloud'>If you need assistance, please contact us.</Typography.Link>
        </Space>
      </Modal>
    </>
  )
}

export default ManageSubscriptionForm
