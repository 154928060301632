import React, { useState, useEffect, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import slugify from 'slugify'
import { Form, Input, Button, Space, notification, Select, Avatar, Typography, Radio, Row, Col, Tooltip, Alert } from 'antd'
import { useAuth } from 'auth-context'
import { AccountPublic, QuotaType, RoleEnum } from '@pollination-solutions/pollination-sdk'
import { CloseOutlined } from '@ant-design/icons'
import { useAccount } from 'hooks'
import { Loading } from 'atoms'

type CreatePayload = {
  owner: string
  name: string
  description: string
  public: boolean
}

type FormValues = {
  public?: boolean
  owner?: string
}

export const NewProject = (): React.ReactElement => {
  const { client } = useAuth()
  const history = useHistory()
  const location = useLocation()
  const [form] = Form.useForm()

  const accountName = Form.useWatch('owner', form)

  const [canCreatePrivate, setCanCreatePrivate] = useState<boolean>(true)
  const [initialValues, setInitialValues] = useState<FormValues>({})
  const [accountOptions, setAccountOptions] = useState<AccountPublic[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const accountType = useMemo(() => {
    if (!accountOptions || accountOptions.length === 0) return
    const account = accountOptions.find((account: AccountPublic) => account.name === accountName)
    return account?.account_type
  }, [accountOptions, accountName])

  const defaultAccount = useMemo(() => {
    if (!location || !location?.pathname) return
    const account = location.pathname.split('/')[1]
    setInitialValues({ owner: account })
    return account
  }, [location])

  const searchAccounts = (search: string | undefined) => {
    client.accounts.listAccounts({ search, role: RoleEnum.Owner })
      .then((res) => setAccountOptions(res.data.resources))
  }

  const createProject = async (payload: CreatePayload) => {
    setLoading(true)

    const slugName = slugify(payload.name, { lower: true })

    return client.projects.createProject({
      owner: payload.owner,
      projectCreate: {
        name: payload.name,
        description: payload.description,
        // @ts-ignore
        public: payload.public,
      }
    }).then(() => history.push(`/${payload.owner}/projects/${slugName}`))
      .catch((err) => {
        const { data } = err.response
        notification.error({
          message: 'API Error',
          description: data.detail
        })
        setLoading(false)
      })

  }

  useEffect(() => {
    searchAccounts(undefined)
  }, [])

  useEffect(() => {
    if (!accountOptions || accountOptions.length === 0) return
    const account = accountOptions.find((account: AccountPublic) => account.name === defaultAccount)
    setInitialValues((prev: any) => ({ ...prev, public: account?.account_type === 'user' }))
  }, [accountOptions, setInitialValues, defaultAccount])


  useEffect(() => {
    if (!accountName) return setCanCreatePrivate(true)
    client.accounts.listQuotas({ name: accountName, type: [QuotaType.PrivateProjects] })
      .then((quotas) => {
        const quota = quotas.data.resources[0]
        return setCanCreatePrivate(!quota.exceeded || quota.enforced || false)
      }).catch((err) => {
        console.error(err)
        setCanCreatePrivate(false)
      })
  }, [accountName, client])



  return (
    <Col
      xl={{ span: 16, offset: 4 }}
      lg={{ span: 16, offset: 4 }}
      md={24}
      sm={24}
      xs={24}>
      <Row gutter={[5, 5]}>
        <Col span={12}>
          <Typography.Title level={4}>
            New Project
          </Typography.Title>
        </Col>
        <Col span={12} style={{ textAlign: 'right' }}>
          <Tooltip
            title='Cancel'>
            <Button shape='circle' type='primary'
              onClick={() => history.go(-1)}
            >
              <CloseOutlined />
            </Button>
          </Tooltip>
        </Col>
        <Col span={24}>
          {(accountType === 'org' && !canCreatePrivate) && (
            <Alert
              message="Cannot Create Project"
              description="This organisation cannot create private projects because it has not purchased a Compute Package."
              type="warning"
              showIcon
            />
          )}
        </Col>
        <Col span={24}>
          {Object.values(initialValues).length === 2 ? <Form
            name="mainForm"
            form={form}
            layout="vertical"
            onFinish={createProject}
            initialValues={initialValues}
          >
            <Space>
              <Form.Item
                name="owner" label="Owner" rules={[{ required: true }]}
              >
                <Select
                  showSearch
                  placeholder="Select an account"
                  onSearch={searchAccounts}
                  disabled={initialValues.owner != null}
                  defaultValue={initialValues.owner}
                  style={{ width: '200px' }}
                >
                  {accountOptions.map(account =>
                    <Select.Option key={account.id} value={account.name}>
                      <Avatar size="small" shape="circle" src={account.picture_url} style={{ backgroundColor: 'white' }} /> {account.name}
                    </Select.Option>
                  )}
                </Select>
              </Form.Item>
              <Form.Item
                name="name" label="Name" rules={[{ required: true }]}
              >
                <Input placeholder="A name for the project" />
              </Form.Item>
            </Space>
            <Form.Item
              name="description" label="Description"
            >
              <Input placeholder="A short description of the project" />
            </Form.Item>
            <Form.Item
              name="public" label="Access"
            >
              <Space direction='vertical' size='small'>
                <Radio.Group defaultValue={initialValues.public} disabled={true}>
                  <Radio value={true}>Public</Radio>
                  <Radio value={false}>Private</Radio>
                </Radio.Group>
                <Typography.Text type='secondary' italic>
                  {initialValues.public
                    ? 'For private projects, consider creating a project under an organization. It gives you a dedicated space for collaborative and confidential work.'
                    : 'Organization projects start private, but you can easily add collaborators later in the settings or change them to public projects.'}
                </Typography.Text>
              </Space>
            </Form.Item>
            <Form.Item>
              <Button loading={loading} htmlType="submit" disabled={accountType === 'org' && !canCreatePrivate}>
                Create
              </Button>
            </Form.Item>
          </Form> : <Loading />}
        </Col>

      </Row>
    </Col>
  )
}
