import React, { useMemo } from 'react'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

import { sdks } from 'hooks/useCreateApplication'
import useApplication from 'hooks/useApplication'
import useApplicationVersions from 'hooks/useApplicationVersions'
import useApplicationDeployment from 'hooks/useApplicationDeployment'
import { Card, Col, Row, Skeleton, Result, Button, Timeline, Typography, Divider, Space, Descriptions } from 'antd'
import Icon from '@ant-design/icons'
import { ImageIcon, userManual } from 'atoms/Icons'
import { AvatarLink } from 'atoms'
import { useAuth } from 'auth-context'

dayjs.extend(utc)

const ApplicationStatus = () => {
    const { idToken } = useAuth()
    const { loading, application } = useApplication()
    const { loading: versionsLoading, latestVersion } = useApplicationVersions()
    const { loading: deploymentLoading, deployment, restartDeployment } = useApplicationDeployment()

    const [restartLoading, setRestartLoading] = React.useState<boolean>(false)

    const timelinePendingMessage = useMemo(() => {
        if (!latestVersion || !deployment) return 'Loading...'
        if (latestVersion.id === deployment.version.id) {
            return ''
        }
        if (latestVersion.build_status.status === 'running') {
            return 'Building...'
        }
        return 'Waiting for build to start...'
    }, [latestVersion, deployment])

    const sdk = useMemo(() => {
        if (!application) return undefined
        return sdks.find((sdk) => sdk.value === application.sdk)
    }, [application])

    return (
        <Card loading={loading}>
            <Skeleton loading={loading || versionsLoading || deploymentLoading} paragraph={{ rows: 4 }}>
                {
                    latestVersion ? (
                        <Row gutter={[24, 24]} align='top'>
                            <Col md={12}>
                                <Row gutter={[24, 24]}>
                                    <Col span={24}>
                                        <Card.Meta
                                            title='Status'
                                            description='The current status of the deployed application'
                                        />
                                    </Col>
                                    <Col span={24}>
                                        <Result
                                            status={deployment?.ready ? 'success' : 'warning'}
                                            title={deployment?.ready ? 'Application is ready to use' : 'Application is not ready to use'}
                                            subTitle={deployment?.ready ? 'Your application is ready to use. Click the link below to open it.' : 'Your application is not ready to use. Please wait for the build to finish.'}
                                            extra={
                                                <>
                                                    <Button
                                                        type='primary'
                                                        disabled={!deployment?.ready}
                                                        href={`${deployment?.url}?token=${idToken}`}
                                                        target='_blank'
                                                    >
                                                        Open Application
                                                    </Button>
                                                    {application && application.permissions.write && (
                                                        <Button
                                                            type='primary'
                                                            loading={restartLoading}
                                                            onClick={() => {
                                                                setRestartLoading(true)
                                                                restartDeployment().finally(() => {
                                                                    setRestartLoading(false)
                                                                })
                                                            }}
                                                        >
                                                            Restart
                                                        </Button>
                                                    )}
                                                </>

                                            }
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={12}>
                                <Row gutter={[24, 24]}>
                                    <Col span={24}>
                                        <Card.Meta
                                            title='Latest Version'
                                            description='Information on the latest version of the application'
                                        />
                                    </Col>
                                    <Col span={24}>
                                        <Descriptions column={1}>
                                            <Descriptions.Item label='SDK'>
                                                <Space align='start'>
                                                    <ImageIcon src={sdk?.icon} />
                                                    <Typography.Text>{sdk?.label}</Typography.Text>
                                                </Space>
                                            </Descriptions.Item>
                                            <Descriptions.Item label='Version'>{latestVersion?.tag}</Descriptions.Item>
                                            <Descriptions.Item label='Author'>
                                                <Space align='start'>
                                                    <AvatarLink link={latestVersion?.author.name} src={latestVersion?.author.picture_url} />
                                                    <Typography.Text>{latestVersion?.author.display_name}</Typography.Text>
                                                </Space>
                                            </Descriptions.Item>
                                        </Descriptions>
                                        <Divider />
                                        <Typography.Title level={5}>Deployment Timeline</Typography.Title>
                                        <Typography.Paragraph>
                                            The timeline below shows the status of the application build and deployment.
                                        </Typography.Paragraph>
                                        <Timeline
                                            pending={timelinePendingMessage}
                                        >
                                            {
                                                latestVersion &&
                                                <Timeline.Item color='green'>{`Created - ${dayjs(latestVersion?.created_at).local()}`}</Timeline.Item>
                                            }
                                            {
                                                latestVersion?.build_status.started_at &&
                                                <Timeline.Item color='green'>{`Build Started - ${dayjs(latestVersion?.build_status.started_at).local()}`}</Timeline.Item>
                                            }
                                            {
                                                latestVersion?.build_status.finished_at &&
                                                <Timeline.Item
                                                    color={latestVersion.build_status.status === 'failure' ? 'red' : 'green'}
                                                >{`Build Finished - ${dayjs(latestVersion?.build_status.finished_at).local()}`}</Timeline.Item>
                                            }
                                            {
                                                (deployment?.version.id == latestVersion?.id) &&
                                                <Timeline.Item
                                                    color={deployment?.ready ? 'green' : 'red'}
                                                >Deployed</Timeline.Item>
                                            }
                                        </Timeline>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    ) : (
                        <Result
                            status="warning"
                            title="You have not yet pushed code to this application."
                            extra={
                                <Row gutter={[8, 8]}>
                                    <Col span={24}>
                                        <Typography.Paragraph>
                                            You can get started by installing the Pollination Apps CLI (<Typography.Text code>pip install pollination-apps</Typography.Text>).
                                        </Typography.Paragraph>
                                        <Col span={24}>
                                            <Button type="primary" key="console" target='_blank' href='https://docs.pollination.cloud/user-manual/apps/introduction'>
                                                <Icon component={userManual} /> User Documentation
                                            </Button>
                                        </Col>
                                        <Divider />
                                        <Typography.Paragraph style={{ textAlign: 'left' }}>
                                            You can then follow the steps below to get started:
                                        </Typography.Paragraph>
                                        <Typography.Paragraph style={{ textAlign: 'left' }}>
                                            <ol>
                                                <li>Clone the application folder: <Typography.Text code>pollination-apps new-from-url {window.location.href} --api-token your-api-token</Typography.Text>.</li>
                                                <li>Follow the instructions in the <Typography.Text code>Readme.md</Typography.Text> file.</li>
                                            </ol>
                                        </Typography.Paragraph>
                                    </Col>
                                </Row>
                            }
                        />
                    )
                }
            </Skeleton>
        </Card>
    )
}

export default ApplicationStatus