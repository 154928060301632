import React from 'react'
import { Grid, Avatar, Space, Typography, Divider, Col } from 'antd'

import footerStyles from './Footer.module.css'

export const Footer = (): React.ReactElement => {
  const screens = Grid.useBreakpoint()

  return (
    <footer style={{ marginTop: 16 }}>
      <Divider orientation="center" style={{ margin: 0 }} />
      <Col lg={24} xl={{ span: 16, offset: 4 }}>
        <Space wrap align="start" direction={screens.xs ? 'vertical' : 'horizontal'} 
          size={'small'} 
          style={{ 
            padding: 16, 
            alignItems: 'center', 
            width: '100%', 
            justifyContent: 'space-between' 
          }}>
          <Space size={8} style={{ alignItems: 'center' }}>
            <Avatar src="/logo.svg" />
            <Typography.Text type="secondary">
              &copy; Pollination {new Date().getFullYear()}
            </Typography.Text>
          </Space>
          <Space direction='horizontal' size='middle'>
            <Typography.Link className={footerStyles['link-secondary']} style={{ color: 'rgba(0, 0, 0, 0.45)' }} href="https://pollination.cloud" target="_blank">
              About
            </Typography.Link>
            <Typography.Text type='secondary'>|</Typography.Text>
            <Typography.Link className={footerStyles['link-secondary']} style={{ color: 'rgba(0, 0, 0, 0.45)' }} href="mailto:info@pollination.solutions" target="_blank">
              Contact Us
            </Typography.Link>
            <Typography.Text type='secondary'>|</Typography.Text>
            <Typography.Link className={footerStyles['link-secondary']} style={{ color: 'rgba(0, 0, 0, 0.45)' }} href="https://api.pollination.cloud" target="_blank">
              API Docs
            </Typography.Link>
            <Typography.Text type='secondary'>|</Typography.Text>
            <Typography.Link className={footerStyles['link-secondary']} style={{ color: 'rgba(0, 0, 0, 0.45)' }} href="https://twitter.com/_pollination" target="_blank">
              Twitter
            </Typography.Link>
          </Space>
        </Space>
      </Col>
    </footer >
  )
}

export default Footer
