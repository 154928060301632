import React, { useState, useRef, useEffect, } from 'react'

import {
  Row,
  Spin,
  notification,
  Layout
} from 'antd'

import { useAuth } from 'auth-context'

import { VTKViewerDrawer, VTKViewer, VTKFloatingToolbar } from 'pollination-viewer'

export interface VTKPollinationProps {
  file: Blob
  type: 'hbjson' | 'vtkjs' | 'vtp' | 'vsf'
  toolbarCollapsed?: boolean
}

const UTILSENDPOINT = process.env.REACT_APP_UTILS_ENDPOINT || 'https://utilities.staging.pollination.cloud'

// setParentState and config are basically just for Streamlit
// a cleaner approach would be to reimpllement VTKPollination as the streamlit component
export const VTKPollination: React.FunctionComponent<VTKPollinationProps> = ({ file, type, toolbarCollapsed,  }) => {
  const viewerRef = useRef<any>()

  const { idToken } = useAuth()

  const [loading, setLoading] = useState<boolean>(false)

  const [viewerState, setViewerState] = useState<any>(undefined)

  // if hbjson, load then pass on to viewer
  useEffect(() => {
    if(!viewerRef.current || !file || file.size < 1) return
    if (type === 'hbjson' || type === 'vsf') {
      setLoading(true)
      file.text().then((fileString) => {
        let query = ''
        let body = ''
        if (type === 'hbjson') {
          query = `${UTILSENDPOINT}/hbjson-to-vtk`
          body = `{
            "output_format": "vtkjs",
            "hbjson": ${fileString}
          }`
        } else {
          query = `${UTILSENDPOINT}/vsf-to-vtk`
          body = `{
            "output_format": "vtkjs",
            "vsf": ${fileString}
          }`
        }

        fetch(query, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': '*/*',
            'x-pollination-token': `Bearer ${idToken}`
          },
          body: body
        }).then(async (res) => {
          if (res.ok) {
            const translatedFile = await res.blob()
            setLoading(false)
            viewerRef.current.loadFile(translatedFile, 'vtkjs')
          } else {
            setLoading(false)
            throw new Error(`Failed to retrieve .vtkjs from .${type} file.Error translating hbjson to vtkjs`)
          }
        })
      })
    } else {
      viewerRef.current.fastLoading(file, type)
    }
  }, [file, idToken, type])

  const handleScreenshot = () => {
    if (!viewerRef.current) return
    viewerRef.current.handleScreenshot('Pollination_screenhot')
      .then(() => {
        notification.success({
          message: 'Copied to Clipboard',
        })
      })
  }

  return (
    <div style={{ height: '100%', width: '100%', display: 'flex' }}>
      <Layout>
        <VTKViewerDrawer dispatch={viewerRef.current?.dispatch} viewerState={viewerState} handleScreenshot={handleScreenshot} />
        <Layout>
          <VTKFloatingToolbar dispatch={viewerRef.current?.dispatch} viewerState={viewerState} handleScreenshot={handleScreenshot} collapsed={toolbarCollapsed} />
          <Layout.Content>
            {loading ?
              <Row justify='center' align='middle' 
                style={{
                  height: '100%',
                  width: 'calc(100% - 60px)',
                  position: 'absolute',
                  padding: 0,
                  margin: 0,
                  backgroundColor: '#ffffff88'
                }}
              >
                <Spin />
              </Row>
              :
              <VTKViewer setViewerState={setViewerState} style={{ height:'100%' }} ref={viewerRef} />
            }
          </Layout.Content>
        </Layout>
      </Layout>
    </div>
  )
}