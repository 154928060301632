import React, { useEffect, useMemo } from 'react'

import { Button, Col, Divider, Row, Space, Tooltip, Typography } from 'antd'
import { AppstoreAddOutlined } from '@ant-design/icons'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'

import { AccountPublic, LicensePoolPublic } from '@pollination-solutions/pollination-sdk'

import { useAuth } from 'auth-context'

import { ProfileSummary } from 'atoms'
import FAQs from 'atoms/FAQs'
import { useAccount, useLicenses } from 'hooks'
import { ProductTemplate } from 'templates'
import { LicensePoolAdmin, LicensePoolMember } from 'organisms'
import { useHistory } from 'react-router-dom'
interface LicensePoolsAdminProps {
  account: AccountPublic
}

const faqs = [
  {
    question: 'I purchased a plugin license, now what?',
    answer: 'Great! You can jump into the relevant platform and retrieve a license for yourself. You can also invite collaborators to your license pool who would then also be able to retrieve licenses.',
  },
  {
    question: 'What\'s the difference between my license pools and license pools I\'m a member of?',
    answer: 'You\'re the administrator of your license pools. You can invite collaborators to retrieve licenses from your license pool, or remove users you\'re no longer working with. You can see any other license pools you have access to, but you can\'t control access to those pools.'
  },
  {
    question: 'What do you mean by "License Pool" and "Floating License"?',
    answer: 'Good question! As stated previously, at this time all licenses you purchase through the Pollination platform are "floating licenses". We use the term "license pool" to refer to your collection of "floating licenses". Any user who has access to your pool, can dynamically retrieve a free license when they log in through a CAD plugin.',
  },
  {
    question: 'How many people can I give access to my plugin license pool?',
    answer: 'Right now you can invite any number of users to your license pool.',
  },
  {
    question: 'Do license pools work differently for users and organizations?',
    answer: 'As of right now no. Whether you\'re using a personal or organization account you can manage access to your license pool in a similar way.',
  },
]

export const LicensePoolsAdmin: React.FunctionComponent<LicensePoolsAdminProps> = () => {
  const { user, loading: loadingAuth } = useAuth()
  const history = useHistory()

  const { account } = useAccount()

  const screens = useBreakpoint()

  const { loading,
    licensePools,
    grantPoolAccess,
    revokePoolAccess,
    getActivations,
    cancelActivation,
    updateLicensePool,
    regenerateLicensePool
  } = useLicenses()

  const { adminPools, memberPools } = useMemo(() => {
    return licensePools.reduce((p: { adminPools: LicensePoolPublic[], memberPools: LicensePoolPublic[] }, c: LicensePoolPublic, i, a) => {
      if (c.owner.name === account?.name) p.adminPools.push(c)
      else p.memberPools.push(c)
      return p
    }, { adminPools: [] as LicensePoolPublic[], memberPools: [] as LicensePoolPublic[] })
  }, [account?.name, licensePools])

  // this enables using this component from app.pollination.cloud/keys
  // user will be redirected to app.pollination.cloud/nicolas/keys
  useEffect(() => {
    if (loadingAuth) return
    if (!account) history.push(`/${user?.username}/licenses`)
  }, [account, history, loadingAuth, user?.username])

  return (
    <ProductTemplate title='The Key to Pollination' 
      subtitle='Manage your floating licenses for Pollination CAD Plugins including Pollination for Rhino and Pollination for Revit.'>
      <Row justify='space-between' gutter={12} wrap style={{
        margin: '16px 0',
        height: 'fit-content', 
        alignSelf: 'flex-start', 
        boxShadow: '0 3px 6px rgba(140,149,159,0.15)',
        padding: 12,
        backgroundColor: '#fff',
        borderRadius: 5
      }}>
        <Col span={24}>
          <ProfileSummary account={account} abbreviated={true} />
        </Col>
        <Col span={24}>
          <Space direction='vertical' style={{ textAlign: screens.xs ? 'center' : 'right', width: '100%' }}>
              <Typography.Title ellipsis={true} level={5}>Manage access to your license pools here.</Typography.Title>
              <Tooltip title="Download Installers and Try Free for 14 Days." overlayInnerStyle={{ textAlign: 'center' }}>
                <Button href={`/${account?.name}?tab=subscription`} type='primary'><AppstoreAddOutlined /> Try Plugins</Button>
              </Tooltip>
            </Space>
        </Col>
      </Row>
      {
        adminPools.map((pool, i) => {
          return (
            <LicensePoolAdmin
              key={i}
              loading={loading}
              pool={pool}
              grantPoolAccess={grantPoolAccess}
              revokePoolAccess={revokePoolAccess}
              getActivations={getActivations}
              cancelActivation={cancelActivation}
              updateLicensePool={updateLicensePool}
              regenerateLicensePool={regenerateLicensePool}
              account={account}
            />
          )
        })
      }
      {account && account.account_type !== 'org' &&
        <LicensePoolMember loading={loading} account={account} memberPools={memberPools} />
      }
      <Divider />
      <FAQs faqs={faqs} />
    </ProductTemplate>
  )
}