import React, { useEffect, useMemo, useRef, useState } from 'react'

import { Link, useHistory, useLocation } from 'react-router-dom'

import { Layout, Grid, Row, Space, Typography, Col } from 'antd'

import { useAuth } from 'auth-context'

import { Dropdown, Option, useWindowDimensions } from 'pollination-react-io'
import Avatar from 'antd/lib/avatar/avatar'

import styles from './Header.module.css'
import { OrganizationList } from '@pollination-solutions/pollination-sdk'
import isEqual from 'lodash.isequal'

import { AccountInfo } from './Header.types'
import { NewOptions } from './NewOptions'
import { AccountOptions } from './AccountOptions'
import { GalleryOptions } from './GalleryOptions'
import { HelpOptions } from './HelpOptions'

export const Header = (): React.ReactElement => {
  const { user, logout, registered, client } = useAuth()
  const history = useHistory()

  const { xs } = Grid.useBreakpoint()
  const { width } = useWindowDimensions()

  const [orgs, setOrgs] = useState<OrganizationList>()
  const [loading, setLoading] = useState(false)

  // DNA: history and location are used without state in lavender
  const location = useLocation()

  const nativeUser: AccountInfo = {
    name: user?.username,
    picture: user?.picture,
    owner: true,
    type: 'user'
  }

  /**
   * DNA: Workaround
  */
  const [accountInfo, setAccountInfo] = useState<AccountInfo>()
  const nameRef = useRef<string>()

  /**
   * Fetch account
   */
  const fetchAccount = async (name: string): Promise<AccountInfo | undefined> => {
    if (!name) return

    if (isEqual(nameRef.current, name)) return

    // Fetch orgs
    fetchOrgs()
    try {
      const { data: account } = await client.accounts.getAccount({ name: name })

      if (account.account_type === 'org') {
        // @ts-ignore
        const { data: org } = await client.orgs.getOrg({ name: account.name })
        if (!org.role) return nativeUser

        nameRef.current = name

        return {
          name: account.name,
          picture: org.picture_url,
          owner: org.role === 'owner',
          type: account.account_type
        }
      } else {
        if (name !== user?.username) return nativeUser

        nameRef.current = name

        return {
          name: account.name,
          picture: account.picture_url,
          owner: name === user?.username,
          type: 'user'
        }
      }
    } catch (error) {
      // Keep the current account
      return nativeUser
    }
  }

  /**
   * Get account from URL
   */
  useEffect(() => {
    if (!user || !location || !location?.pathname) return
    const accountName = location.pathname.split('/')[1]
    const root = accountInfo?.name ?? user?.username
    const name = (['apps', 'recipes', ''].includes(accountName))
      ? root
      : accountName
    fetchAccount(name).then((res) => {
      res && setAccountInfo(res)
    })
  }, [user, location])

  /**
   * Fetch organization
   */
  const fetchOrgs = () => {
    if (!user || !user.username) return
    setLoading(true)
    client.orgs.listOrgs({ member: [user.username] })
      .then(({ data }) => {
        setOrgs(data)
      })
      .finally(() => setLoading(false))
  }

  const accountItems = useMemo(() => {
    if (!user || !orgs || orgs?.total_count === 0) return undefined

    const orgsItems = orgs && orgs.resources.map((org) =>
    (
      {
        type: 'button',
        icon: <Avatar src={org.picture_url} size={18} shape='circle' />,
        label: org.name,
        id: org.id,
        onSelect: () => history.push(`/${org.account_name}?tab=projects`)
      }
    )
    )

    const mainAccount = {
      type: 'button',
      icon: <Avatar src={user?.picture} size={18} shape='circle' />,
      label: user?.name,
      id: user?.id,
      onSelect: () => history.push(`/${user?.username}?tab=projects`)
    }

    return [
      mainAccount
    ].concat(orgsItems) as Option[]
  }, [orgs])

  return (
    <Layout.Header
      style={{
        background: 'white',
        borderBottom: '1px solid #91d5ff',
        padding: '0 16px',
        margin: 0
      }}>
      <Row justify={'space-between'}>
        {/* Home button */}
        <Col>
          <Link to="/">
            <img src="/brandmark.svg" alt="Pollination Logo" style={{ height: xs ? 26 : 30, marginBottom: 4 }} />
          </Link>
        </Col>
        <Col>
          <Space align='center'
            direction='horizontal'
            size={xs ? 'small' : 'middle'}>

            {/* Help options - limit iPhoneSE width */}
            {width > 375 && user && accountInfo && registered && <HelpOptions accountInfo={accountInfo}
              disabled={typeof user === 'undefined'}
            />}

            {/* New options */}
            {user && accountInfo && registered && <NewOptions accountInfo={accountInfo}
              disabled={typeof user === 'undefined'}
            />}

            {/* Gallery options */}
            {user && accountInfo && registered && <GalleryOptions accountInfo={accountInfo}
              disabled={typeof user === 'undefined'}
            />}

            {/* Account options */}
            <div>
              {user && accountInfo && registered &&
                <Dropdown
                  disabled={loading}
                  trigger={
                    <button className={styles['header-avatar-button']}>
                      <Space direction='horizontal' size={'small'} className={styles['header-avatar-container']}>
                        <Avatar
                          src={accountInfo?.picture}
                          size={30} shape='circle'
                        />
                        {!xs && <Typography.Text
                          strong>
                          {accountInfo?.name}
                        </Typography.Text>}
                      </Space>
                    </button>
                  }
                  optionGroups={[
                    {
                      type: 'default',
                      options: loading ? [] : (accountItems ?? [
                        {
                          type: 'link',
                          icon: <Avatar src={accountInfo?.picture} size={18} shape='circle' />,
                          label: user?.name,
                          id: user?.id,
                          to: `/${accountInfo?.name}?tab=projects`
                        }
                      ] as Option[])
                    }
                  ]}
                  contentProps={{
                    align: 'end',
                    sideOffset: -8,
                    style: {
                      borderColor: 'var(--primary)',
                      zIndex: 1000,
                      lineHeight: '1.35rem',
                    }
                  }}
                />}

              {<AccountOptions accountInfo={accountInfo}
                disabled={typeof user === 'undefined'}
                logout={logout}
              />}

            </div>
          </Space>
        </Col>
      </Row>
    </Layout.Header>
  )
}

export default Header
