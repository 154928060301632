import React, { useEffect, useState } from 'react'
import { AppstoreAddOutlined, ClusterOutlined, 
  PlusCircleOutlined, ProjectOutlined, TeamOutlined } from '@ant-design/icons'
import { AccountInfo } from './Header.types'

import { Dropdown, OptionGroup } from 'pollination-react-io'

import styles from './Header.module.css'
import { Avatar } from 'antd'
import { useHistory } from 'react-router-dom'

interface NewOptionsProps {
  accountInfo: AccountInfo
  disabled: boolean
}

export const NewOptions = ({
  accountInfo,
  disabled
}): React.ReactElement<NewOptionsProps> => {
  const history = useHistory()

  const newOptions: OptionGroup[] = [{
    options: [
      {
        type: 'button',
        label: 'New Study',
        id: 'new-job',
        icon: <ClusterOutlined />,
        onSelect: () => history.push(`/${accountInfo?.name}/new/study`)
      }
    ]
  }]

  const newTeam: OptionGroup[] = [{
    options: [
      {
        type: 'button',
        label: 'New Team',
        id: 'new-team',
        icon: <TeamOutlined />,
        onSelect: () => history.push(`/${accountInfo?.name}/new/team`)
      }
    ] 
  }]

  const [options, setOptions] = useState<OptionGroup[]>([])

  useEffect(() => {
    if (!accountInfo) return

    const baseOptions: OptionGroup[] = accountInfo?.owner
      ? [{
        options: [
          {
            type: 'button',
            label: 'New Project',
            id: 'new-project',
            icon: <ProjectOutlined />,
            onSelect: () => history.push(`/${accountInfo?.name}/new/project`)
          },
          ...newOptions[0].options,
          {
            type: 'button',
            label: 'New App',
            id: 'new-app',
            icon: <AppstoreAddOutlined />,
            onSelect: () => history.push(`/${accountInfo?.name}/new/application`)
          }
        ]
      }]
      : newOptions

      if (accountInfo.type === 'org' && accountInfo.owner) {
        baseOptions.push(...newTeam)
      }

      setOptions(baseOptions)
  }, [accountInfo])

  return (
    <Dropdown
    disabled={disabled}
    trigger={
      <button className={styles['header-avatar-button']}>
        <Avatar icon={<PlusCircleOutlined style={{ 
            color: '#333',
            fontSize: 20
           }}/>} 
          size={30} 
          shape='circle'
          className={styles['header-avatar-trigger']}
          style={{ backgroundColor: 'rgba(0,0,0,0)' }}
        />
      </button>
    }
    optionGroups={options}
    contentProps={{
      align: 'end',
      sideOffset: -8,
      style: {
        borderColor: 'var(--primary)',
        zIndex: 1000,
        lineHeight: '1.6rem'
      }
    }}
  />)
}