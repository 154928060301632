import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router'
import { Row, Col, Card, Space, Typography, notification } from 'antd'

import {
  InfoCircleOutlined,
  FunctionOutlined,
  NumberOutlined,
  NodeIndexOutlined
} from '@ant-design/icons'

import { Indigo } from '@pollination-solutions/indigo'
import { RecipePackage, Recipe, Repository } from '@pollination-solutions/pollination-sdk'
import Markdown from 'react-markdown'

import { useAuth } from 'auth-context'
import { Loading, Error } from 'atoms'

import Tabs from 'components/Tabs'
import RecipeInterfaceCard from './RecipeInterfaceCard'
import PluginTagsList from './TagsList'

export const RecipePage = () => {
  // @ts-ignore
  const { accountName, recipeName, recipeTag } = useParams()
  const { client } = useAuth()

  const [loading, setLoading] = useState<boolean>(true)
  const [fetchError, setFetchError] = useState<any | undefined>()
  const [repository, setRepository] = useState<Repository | undefined>()
  const [snapshot, setSnapshot] = useState<RecipePackage | undefined>()
  const [recipeJson, setRecipeJson] = useState<Recipe | undefined>()
  const [tabs, setTabs] = useState<any[]>([])

  const openFunction = typeof window === 'undefined' ? '' : window.location.href.split('#')[1] ?? ''

  // @ts-ignore
  window.process = { cwd: () => '' }

  useEffect(() => {
    setLoading(true)
    client.recipes.getRecipe({ owner: accountName, name: recipeName })
      .then(async ({ data }) => {
        setRepository(data)

        // @ts-ignore
        if (data.public || data.permissions?.write) {
          client.registries.getPackageJson({
            owner: accountName,
            type: 'recipe',
            name: recipeName,
            digest: recipeTag ?? 'latest',
          })
            .then(({ data }) => setRecipeJson(data as Recipe))
        }

        return client.recipes.getRecipeByTag({
          owner: accountName,
          name: recipeName,
          tag: recipeTag ?? 'latest'
        })
          .then(({ data }) => setSnapshot(data))
      })
      .catch((err) => setFetchError(err))
    // .finally(() => setLoading(false))
  }, [accountName, recipeName, recipeTag])

  useEffect(() => {
    if (fetchError) {
      return setLoading(false)
    }
    if (!snapshot || !repository) {
      setLoading(true)
      return setTabs([])
    }

    const newTabs = [
      {
        label: 'Recipe',
        icon: <FunctionOutlined />,
        content: <RecipeInterfaceCard styles={styles.cardBody} recipePackage={snapshot} recipeJson={recipeJson} defaultOpen={openFunction} />,
      },
      {
        label: 'Readme',
        icon: <InfoCircleOutlined />,
        content: <Card><Markdown source={snapshot.readme ? snapshot.readme : '# No Readme'} /></Card>,
      },
      {
        label: 'Tags',
        icon: <NumberOutlined rotate={45} />,
        content: <PluginTagsList repository={repository} owner={accountName} name={recipeName} />,
      },

    ]

    if (recipeJson) {
      newTabs.splice(2, 0, {
        label: 'Graph',
        icon: <NodeIndexOutlined />,
        content: (
          <Card style={{ width: '100%' }}>
            <div style={{ width: '95vw', height: '90vh' }}>
              {/* @ts-ignore */}
              <Indigo.IndigoRoot layout={{ direction: 'LR' }} meta={{ owner: accountName, domain: 'https://app.pollination.cloud' }}>
                <Indigo.Controls.FlowSelect />
                <Indigo.Controls.Zoom />
                {/* @ts-ignore */}
                <Indigo.RecipeViewer recipe={recipeJson} />
              </Indigo.IndigoRoot>
            </div>
          </Card>
        )
      })
    }
    setTabs(newTabs)
    setLoading(false)
  }, [snapshot, repository, recipeJson, fetchError])

  useEffect(() => {
    if (snapshot && snapshot.manifest.metadata.deprecated) {
      notification.warning({
        message: `${accountName}/${snapshot.manifest.metadata.name}:${snapshot.manifest.metadata.tag} is deprecated`,
        description: 'This recipe is no longer supported. Contact recipe author or use a different version.'
      })
    }
  }, [snapshot])

  const styles = {
    cardBody: {
      minHeight: '300px',
      overflow: 'auto',
    },
  }

  if (loading) {
    return (
      <>
        <Row>
          <Col>
            <Space wrap>
              <NodeIndexOutlined style={{ fontSize: '24px' }} />
              <Typography.Text style={{ fontSize: '20px' }}>
                <Link to={`/${accountName}?tab=recipes`}>{accountName}</Link>
              </Typography.Text>
              <Typography.Text style={{ fontSize: '20px' }} >/</Typography.Text>
              <Typography.Text strong style={{ fontSize: '20px' }}>
                <Link to={`/${accountName}/recipes/${recipeName}/${recipeTag ?? 'latest'}`}>{recipeName}</Link>
              </Typography.Text>
            </Space>
          </Col>
        </Row>
        <Loading />
      </>
    )
  }

  return (
    <>
      <Row>
        <Col>
          <Space wrap>
            <NodeIndexOutlined style={{ fontSize: '24px' }} />
            <Typography.Text style={{ fontSize: '20px' }}>
              <Link to={`/${accountName}?tab=recipes`}>{accountName}</Link>
            </Typography.Text>
            <Typography.Text style={{ fontSize: '20px' }} >/</Typography.Text>
            <Typography.Text strong style={{ fontSize: '20px' }}>
              <Link to={`/${accountName}/recipes/${recipeName}/${recipeTag ?? 'latest'}`}>{recipeName}</Link>
            </Typography.Text>
          </Space>
        </Col>
      </Row>
      {
        fetchError ? <Error error={fetchError} /> : <Tabs tabs={tabs} />
      }
    </>
  )
}
