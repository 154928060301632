import React, { FC, useCallback, useState } from 'react'

import { AccountPublic } from '@pollination-solutions/pollination-sdk'

import { Avatar, Button, Col, Divider, Input, notification, Popconfirm, Row, Space, Typography } from 'antd'

import { ImageUpload } from 'molecules'

import { InputLabel, Loading } from 'atoms'

import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import { UpdatingIcon } from 'atoms/UpdatingIcon'

import * as AspectRatio from '@radix-ui/react-aspect-ratio'
import { useOrganizations } from 'hooks'
import { DeleteOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import { useAuth } from 'auth-context'

interface ProfileSummaryProps {
  account: AccountPublic
  updateAccount: (update: Partial<AccountPublic>) => Promise<void>
  deleteAccount: () => Promise<void>
  refetch: (args?: any) => void
}

const APIBASEPATH = process.env.REACT_APP_API_BASE_PATH ? process.env.REACT_APP_API_BASE_PATH : 'https://api.staging.pollination.cloud'

export const ProfileSettings: FC<ProfileSummaryProps> = ({
  account,
  updateAccount,
  deleteAccount,
  refetch
}) => {
  const { xs } = useBreakpoint()
  const history = useHistory()

  const { user, logout } = useAuth()

  const [loadingName, setLoadingName] = useState(false)
  const [loadingDescription, setLoadingDescription] = useState(false)

  const { deleteOrg } = useOrganizations()
  
  const updateName = useCallback((e: any) => {
    if (!e || !e.target || !e.target.value) return
    
    const display_name = e.target.value
    if (display_name === account.display_name) return

    setLoadingName(true)
    updateAccount({ display_name })
      .finally(() => setLoadingName(false))
      .catch(() => setLoadingName(false))

  }, [account.display_name, updateAccount])

  const updateDescription = useCallback((e: any) => {
    if (!e || !e.target || !e.target.value) return
    
    const description = e.target.value
    if (description === account.description) return

    setLoadingDescription(true)
    updateAccount({ description })
      .finally(() => setLoadingDescription(false))
      .catch(() => setLoadingDescription(false))

  }, [account.description, updateAccount])

  if(!account) return <Loading/>

  return (
    <Row gutter={[16, 24]}>
      <Col span={xs ? 24 : 6}>
        <AspectRatio.Root ratio={1}>
          <Avatar src={`${account.picture_url}?${performance.now()}`} style={{ width: '100%', objectFit: 'cover', height: '100%', }} />
        </AspectRatio.Root >
        <div style={{ height: 0 }}>
          <Row className='p-image-overlay p-backdrop-blur' 
            style={{ padding: '4px 8px', columnGap: 16 }} align='middle'>
            <ImageUpload endpoint={
              account.account_type === 'user' ?
                `${APIBASEPATH}/user/image` :
                `${APIBASEPATH}/orgs/${account.name}/image`
            }
            refetch={refetch} />
          </Row>
        </div>
      </Col>
      <Col span={xs ? 24 : 18}>
        <Space direction='vertical' style={{ width: '100%' }} size={8}>
          <Row
            justify='space-between'
          >
            <Space split={<Divider type='vertical'/>} align='baseline'>
              <Typography.Title level={3} style={{ marginBottom: 0 }}>{account.display_name}</Typography.Title>
              <Typography.Title level={4} type='secondary' style={{ marginBottom: 0 }}>@{account.name}</Typography.Title>  
            </Space>
            {account.account_type === 'org' &&
              <Popconfirm
                title={`Delete ${account.name}?`}
                cancelText='Nevermind'
                onConfirm={
                  () => deleteOrg(account.name)
                    .catch(() => notification.warning({
                      message: `Could not delete ${account.name}.`
                    }))
                    .then(() => history.push(`/${user?.username}`))
                }
                okText='Delete'
              >
                <Button danger size='small'>
                  <DeleteOutlined/>
                  Delete Org
                </Button>
              </Popconfirm>
            }
            {account.account_type === 'user' &&
              <Popconfirm
                title={<div style={{  
                  width: 260
                }}>
                  Deleting your account will delete your access and all your information on Pollination.
                  All the studies and the files and folders will be deleted. 
                  If you have a recurring subscription they will also be automatically 
                  canceled as part of this action. <strong>This action is not recoverable</strong>. 
                  Are you sure you want to continue?
                </div>}
                cancelText='Nevermind'
                onConfirm={
                  () => deleteAccount()
                    .catch(() => notification.warning({
                      message: `Could not delete ${account.name}.`
                    }))
                    .then(() => logout())
                }
                okText='Delete'
              >
                <Button danger size='small'>
                  <DeleteOutlined/>
                  Delete Account
                </Button>
              </Popconfirm>
            }
          </Row>
          <Divider style={{ margin: '0px 0px 24px' }} />
          <Row gutter={[16, 0]} justify='space-between'>
            <Col>
              <UpdatingIcon loading={loadingName}/>
            </Col>
            <InputLabel label='Display Name' style={{ flex: 1 }}>
              <Input defaultValue={account.display_name}
                onPressEnter={updateName}
                onBlur={updateName}
              />
            </InputLabel>
          </Row>
          <Row gutter={[16, 0]} justify='space-between'>
            <Col>
              <UpdatingIcon loading={loadingDescription}/>
            </Col>
            <InputLabel label='Description' style={{ flex: 1 }}>
              <Input.TextArea defaultValue={account.description}
                onPressEnter={updateDescription}
                onBlur={updateDescription}
              />
            </InputLabel>
          </Row>
        </Space>
      </Col>
    </Row>
  )
}