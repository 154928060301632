import React, { useCallback, useState } from 'react'

import { ApplicationUpdate } from '@pollination-solutions/pollination-sdk'

import { Avatar, Button, Card, Col, Divider, Input, Modal, notification, Row, Space, Tag, Typography, Collapse, Descriptions, Switch, Form } from 'antd'

import { AppsResourceManager, ImageUpload } from 'molecules'

import { KeywordSelect, Loading } from 'atoms'

import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import { useHistory } from 'react-router-dom'

import { DEFAULT_DEPLOYMENT_CONFIG } from 'hooks'
import { ApplicationVisibilityEnum } from 'hooks/useCreateApplication'
import useApplication from 'hooks/useApplication'
import FormItemVisibility from 'molecules/FormItemVisibility'

const APIBASEPATH = process.env.REACT_APP_API_BASE_PATH ? process.env.REACT_APP_API_BASE_PATH : 'https://api.staging.pollination.cloud'

export const ApplicationSettings: React.FC = () => {
  const { xs, xl } = useBreakpoint()

  const history = useHistory()

  const { loading, application, applicationVisibility, reloadApplication, updateApplication, deleteApplication } = useApplication()

  const [modalVisible, setModalVisible] = useState(false)

  const handleUpdateApp = useCallback(async (config: Partial<ApplicationUpdate>, addDefault?: boolean) => {
    if (!application) return

    if (addDefault) {
      return updateApplication({ ...config, ...DEFAULT_DEPLOYMENT_CONFIG })
    }

    return updateApplication(config)
  }, [application, updateApplication])


  if (!application) return <Loading />

  return (
    <><Row gutter={[16, 24]}>
      <Col span={xl ? 18 : 24}>
        <Card
          bodyStyle={{ height: '100%' }}
        >
          <Row gutter={[24, 24]} style={{ height: '100%' }}>
            <Col span={8}>
              <Avatar src={`${application.image}?${performance.now()}`} shape='square' style={{ width: '100%', height: 'auto' }} />
              <div style={{ height: 0 }}>
                <div className='p-image-overlay p-backdrop-blur' style={{ padding: '4px 8px', columnGap: 16, display: 'flex', alignItems: 'center' }}>
                  <ImageUpload endpoint={`${APIBASEPATH}/applications/${application.owner.name}/${application.slug}/image`}
                    refetch={() => reloadApplication()}
                    buttonProps={xs ? { size: 'small', shape: 'circle' } : undefined}
                    buttonText={xs ? '' : undefined} />
                </div>
              </div>
            </Col>
            <Col span={16}>
              <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Space split={<Divider type='vertical' />}>
                    <Typography.Title level={3} style={{ marginBottom: 0 }}>{application.name}</Typography.Title>
                    {application.license &&
                      <Tag>{application.license}</Tag>}
                  </Space>
                  <Button danger onClick={() => setModalVisible(true)}>Delete App</Button>
                </div>
                <Space split={<Divider type='vertical' />} align='baseline'>
                  <Typography.Text>
                    {application.is_paid ?
                      'This app is powered by Pollination Application Resources.' :
                      'This app isn\'t using any Pollination Application Resources.'}
                  </Typography.Text>
                </Space>
                <Form layout='vertical'>
                  <Row justify='space-between' gutter={[16, 16]}>
                    <Col span={8}>
                      <FormItemVisibility
                        initialValue={applicationVisibility}
                        value={applicationVisibility}
                        onChange={(val) => {
                          const updatePublic = {
                            public: val !== ApplicationVisibilityEnum.Private,
                            deployment_config: {
                              ...DEFAULT_DEPLOYMENT_CONFIG.deployment_config,
                              login_required: val !== ApplicationVisibilityEnum.Public,
                            }
                          }
                          // @ts-ignore
                          handleUpdateApp(updatePublic, false)
                        }}
                        style={{ width: '100%' }}
                        disabled={loading}
                        loading={loading}
                        enablePaidOptions={application.is_paid}
                      />
                    </Col>
                    <Col span={16}>
                      <Form.Item name='keywords' label='Keyword' initialValue={application.keywords}>
                        <KeywordSelect onChange={(keywords) => { handleUpdateApp({ keywords }) }} loading={loading} value={application.keywords} placeholder={'Add keyword tags'} />
                      </Form.Item>
                    </Col>
                    <Col span={24}>

                      <Form.Item name='description' label='Description' initialValue={application.description}>
                        <Input.TextArea
                          disabled={loading}
                          onPressEnter={(e) => {
                            // @ts-ignore
                            if (e.target.value === application.description) return
                            // @ts-ignore
                            handleUpdateApp({ description: e.target.value })
                          }}
                          onBlur={(e) => {
                            if (e.target.value === application.description) return
                            handleUpdateApp({ description: e.target.value })
                          }} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>

              </div>
            </Col>
            {application.is_paid && (
              <Col span={24}>
                <Collapse ghost>
                  <Collapse.Panel key='advanced' header='Advanced'>
                    <Typography.Text type='secondary'>The settings below are for advanced and experimental features.</Typography.Text>
                    <Descriptions column={1}>
                      <Descriptions.Item label="Entrypoint">
                        <Input
                          style={{ maxWidth: '250px' }}
                          defaultValue={application.deployment_config?.entrypoint_file ?? 'app.py'}
                          disabled={loading}
                          onPressEnter={(e) => {
                            // @ts-ignore
                            if (e.target.value === application.deployment_config?.entrypoint_file) return
                            // @ts-ignore
                            handleUpdateApp({ deployment_config: { entrypoint_file: e.target.value } })
                          }}
                          onBlur={(e) => {
                            if (e.target.value === application.deployment_config?.entrypoint_file) return
                            handleUpdateApp({ deployment_config: { entrypoint_file: e.target.value } })
                          }}
                        />
                      </Descriptions.Item>
                    </Descriptions>
                  </Collapse.Panel>
                </Collapse>
              </Col>
            )}
          </Row>
        </Card>
      </Col>
      <Col span={xl ? 6 : 24}>
        <AppsResourceManager application={application} handleUpdateApp={handleUpdateApp} />
      </Col>
    </Row>
      <Modal visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        okText={'Confirm'}
        okButtonProps={{ danger: true }}
        onOk={() => {
          deleteApplication()
            .then(() => {
              history.push('/')
            })
            .catch((e) => {
              notification.error({
                message: 'Something went wrong!',
                description: e.message
              })
            }).finally(() => setModalVisible(false))
        }}
      >
        <Space direction='vertical'>
          <Typography.Title level={5}>Are you sure you want to delete this application?</Typography.Title>
          <Typography.Text>This app deployment will not be recoverable, but the app developer should have versions of the app source code.</Typography.Text>
          <Typography.Text>Any App Resources allocated to this app will be freed.</Typography.Text>
        </Space>
      </Modal>
    </>
  )
}