import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { useHistory, useParams } from 'react-router'
import useSWR from 'swr'

import { Run } from '@pollination-solutions/pollination-sdk'

import { useAuth } from 'auth-context'

import { Loading, Error } from 'atoms'

import { RunCard, useRuns, useWindowDimensions } from 'pollination-react-io'
import { RunPageDetails } from '../../organisms/RunDetails'
import { RunDebugger } from 'components/Run'
import Folder from 'components/Folder'
import { useLocation } from 'react-router-dom'
import { FolderCardProps } from 'pollination-react-io/build/RunDetails/Cards/FolderCard'
import { useAccount } from 'hooks'
import { Col } from 'antd'


enum RunTabs {
  details = 'details',
  debug = 'debug',
  workspace = 'workspace'
}

export const RunPage: React.FunctionComponent = () => {
  const { client, user: authUser } = useAuth()
  const { search } = useLocation()
  const history = useHistory()
  const { canWrite } = useAccount()
  const { width } = useWindowDimensions()

  const { accountName, projectName, studyId, runId } = useParams<{
    accountName: string, projectName: string, studyId: string, runId: string
  }>()

  /*
   * Query param for first run
   */
  const [currTab, setCurrTab] = useState<RunTabs>(RunTabs[
    new URLSearchParams(search).get('tab') ?? 'details'])

  const setQueryString = (tab: string,
    path: string | undefined = undefined) => {
    const query = new URLSearchParams(search)

    if (query.get('tab')) {
      query.set('tab', tab)

      if (tab !== RunTabs.workspace) {
        query.delete('path')
      } else {
        path && query.set('path', path) // optional path
      }

    } else {
      query.append('tab', tab)
    }

    history.push({
      search: query.toString()
    })
  }

  useEffect(() => {
    // Get tab from history
    setCurrTab(RunTabs[new URLSearchParams(search).get('tab') ?? 'details'])
  }, [search])

  const getTab = useCallback((tab: string) => {
    setQueryString(tab)
    setCurrTab(RunTabs[tab])
  }, [currTab])

  /*
   * Fetchers 
   */
  const fetchRun = useCallback((owner: string, name: string, runId: string): Promise<Run> => {
    if (!client || !client.runs) return Promise.reject()

    return client.runs.getRun({ owner, name, runId })
      .then(({ data }) => {

        return data
      })
  }, [client])

  const [run, setRun] = useState<Run>()
  const [fetchError, setFetchError] = useState<any>()

  useEffect(() => {
    if (!accountName || !projectName || !runId) return

    fetchRun(accountName, projectName, runId)
      .then(d => setRun(d))
      .catch((err) => setFetchError(err))
  }, [accountName, projectName, runId])

  /*
   * Workspace 
   */
  const listArtifacts = useCallback((path: string | undefined) => {
    if (!client || !client.jobs) return Promise.reject()

    let key: string[] | undefined = undefined

    if (path) {
      key = [path]
    }

    return client.jobs.searchJobFolder({
      owner: accountName,
      name: projectName,
      jobId: studyId,
      page: 1,
      perPage: 1000,
      path: key
    }).then(r => r.data.resources)
  }, [accountName, client, studyId, projectName])

  const downloadArtifact = useCallback((path: string) => {
    if (!client || !client.jobs) return Promise.reject()

    return client.jobs.downloadJobArtifact({
      owner: accountName,
      name: projectName,
      jobId: studyId,
      path
    }).then(r => r.data)
  }, [accountName, client, studyId, projectName])

  /*
   * Folder card handler
   */
  const [currPath, setCurrPath] = useState<string>()

  const getFolderCardHandler = useCallback((card: FolderCardProps) => {
    setCurrTab(RunTabs.workspace)
    setCurrPath(card.path)
    setQueryString(RunTabs.workspace, card.path) // Push to history
  }, [currPath, currTab])

  /**
   * Get run value
   */
  const [runCallback, setRunCallback] = useState<Run>()
  const getValueHandler = (currRun: Run) => {
    setRunCallback((prev) => {
      if (prev?.status?.finished_at !== currRun?.status?.finished_at) {
        return { ...prev, ...currRun } // rerender
      } else {
        return prev // no rerender
      }
    })
  }

  // @ts-ignore
  const { getTrigger } = useRuns(client)
  // @ts-ignore
  const trigger = useMemo(() => run && getTrigger(run), [run])

  return (
    <Col
      xl={{ span: 16, offset: 4 }}
      lg={{ span: 16, offset: 4 }}
      md={24}
      sm={24}
      xs={24}>
      {fetchError ? (
        <Error error={fetchError} />
      ) : <>
        {run ? <RunCard
          style={{ backgroundColor: 'white' }}
          projectName={projectName}
          projectOwner={accountName}
          // @ts-ignore
          run={run}
          // @ts-ignore
          client={client}
          authUser={authUser}
          enableClick={false}
          getTab={getTab}
          canWrite={canWrite}
          interval={10000}
          getValue={getValueHandler}
          defaultTab={currTab} // Hightlight the tab from url
        /> : <Loading />}

        {/* Tabs logic */}
        {currTab === RunTabs.details &&
          <RunPageDetails
            getFolderCard={getFolderCardHandler}
            accountName={accountName}
            projectName={projectName}
            run={runCallback}
            authUser={authUser}
            // @ts-ignore
            client={client}
            isCloud={true}
          />}

        {run && currTab === RunTabs.debug &&
          <div style={{ margin: '10px 0 0 0' }}>
            <RunDebugger
              owner={accountName}
              projectName={projectName}
              run={run} />
          </div>}

        {run && currTab === RunTabs.workspace &&
          <div style={{ margin: '10px 0 0 0' }}>
            {/* Use ?path=runs/run.id */}
            <Folder
              initialPath={currPath ?? (trigger ? '' : `runs/${run.id}/workspace`)}
              listArtifacts={listArtifacts}
              downloadArtifact={downloadArtifact} />
          </div>}
      </>}
    </Col>
  )
}