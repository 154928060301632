import React from 'react'

import { Button, Typography } from 'antd'
import UpgradeTemplate from './ProductsTemplate'
import { ProductCard } from './ProductsTemplate.types'

const planFeatures: ProductCard[] = [
  {
    title: 'Starter',
    description: 'Get started with Pollination and collaborate on public projects.',
    action: <Button size='large' type='primary'>Upgrade</Button>,
    features: [
      'Unlimited Public Projects & Recipes',
      '8 Concurrent CPUs',
      '10 CPU Hours / mo',
      '5 GB Data Storage',
      'Basic Collaboration',
    ],
    productName: 'pollination_plan',
    resourceName: 'zero-tier'
  },
  {
    title: 'Professional',
    description: 'Bring the power of Pollination to your business and work with collaborators on private projects.',
    action: <Button size='large' type='primary'>Upgrade</Button>,
    features: [
      'Private Projects & Recipes',
      '12 Concurrent CPUs',
      'Up to 72 Concurrent CPUs',
      '100 CPU Hours / mo',
      '100 GB Data Storage',
      'Purchase Cloud Resources on Demand'
    ],
    productName: 'pollination_plan',
    resourceName: 'professional-tier'
  },
  {
    title: 'Organization',
    description: 'Enable Pollination\'s advanced collaboration features and maximum computational power',
    action: <Button size='large' type='primary'>Upgrade</Button>,
    features: [
      '36 Concurrent CPUs',
      'Up to 360 Concurrent CPUs',
      '300 CPU Hours / mo',
      '500 GB Data Storage',
      'Purchase Cloud Resources on Demand',
      'Advanced Collaboration'
    ],
    productName: 'pollination_plan',
    resourceName: 'organization-tier'
  },
]

const title = {
  title: 'Choose the Best Pollination Plan for your Team',
  subtitle: <Typography.Link href='https://www.pollination.cloud/pricing-platform' target='_blank'>View a comprehensive comparison of all Pollination plans.</Typography.Link>,
  footer: 'Don\'t see the plan you need? Get in touch!'
}


const faqs = [
  {
    key: 'organizations',
    question: 'How do Pollination Organizations work?',
    answer: 'Organizations provide the highest level of Pollination Cloud resources and enable advanced collaboration features that help you organize your work.'
  },
  {
    key: 'professional',
    question: 'Why would I need a Professional Account?',
    answer: 'The Professional Plan provides additional cloud resources, and the use of private projects and recipes.'
  },
  {
    key: 'plugins',
    question: 'Do I need a Pollination Plan to purchase CAD plugins?',
    answer: 'You do not need a paid Pollination Plan to purchase CAD Plugins.'
  }
]

const PollinationPlans = () => {
  return (
    <UpgradeTemplate title={title} cards={planFeatures} faqs={faqs} />
  )
}

export default PollinationPlans