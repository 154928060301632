import React from 'react'
import 'react-app-polyfill/ie11'
import ReactDOM from 'react-dom'

import App from './App'

import reportWebVitals from './reportWebVitals'
import * as serviceWorker from './serviceWorker'

import 'antd/dist/antd.css'

import { FirebaseAppProvider } from 'reactfire'

const PROJECT_ID = process.env.REACT_APP_PROJECT_ID || 'pollination-staging-1d6a8'
const AUTHDOMAIN = process.env.REACT_APP_API_BASE_PATH ? process.env.REACT_APP_API_BASE_PATH : 'app.staging.pollination.cloud'


const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY || 'AIzaSyC9O5jhCnmIpC6ZcJy6I55P4r2T2S-INEU',
  authDomain: AUTHDOMAIN.replace('https://api', 'app'),
  databaseURL: `https://${PROJECT_ID}.firebaseio.com`,
  projectId: PROJECT_ID,
  storageBucket: `${PROJECT_ID}.appspot.com`,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID || '893599506119',
  appId: process.env.REACT_APP_FIREBASE_APP_ID || '1:893599506119:web:c805dbcda9c2f65fdf28e2',
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID || 'G-JZ386JEVSM'
}

ReactDOM.render(
  <>
    <React.StrictMode>
      <FirebaseAppProvider firebaseConfig={firebaseConfig} suspense>
        <App/>
      </ FirebaseAppProvider>
    </React.StrictMode>
    
  </>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()