import React from 'react'

import { Button, Typography } from 'antd'
import { ThunderboltFilled } from '@ant-design/icons'

import useDownloadPlugin from 'hooks/useDownloadPlugin'

import ProductsTemplate from './ProductsTemplate'
import { ProductCard } from './ProductsTemplate.types'

const defaultTitle = {
  title: 'Looking for CAD Plugins?',
  subtitle: 'Freedom & Flexibility to Model Real World Complexity.',
  footer: <Typography.Link href='https://www.pollination.cloud/pricing-cad-plugins' target={'_blank'} referrerPolicy="no-referrer">Visit our pricing page for more details.</Typography.Link>
}

const faqs = [
  {
    question: 'How does licensing work?',
    answer: 'When you purchase a Rhino or Revit plugin you will be given access to a license key through your Pollination account.'
  },
  {
    question: 'Do I need a Pollination Professional or Organization account to get the CAD Plugins?',
    answer: 'No, any level of Pollination account (including the free Starter level) will allow you to purchase Pollination CAD Plugins.'
  },
  {
    question: 'What version of Rhino should I use?',
    answer: 'The Pollination Rhino Plugin works optimally with Rhino 7. If you are using Rhino 6, you must use version 6.23 or above.'
  },
  {
    question: 'What version of Revit should I use?',
    answer: 'The Pollination Revit Plugin works optimally with Revit 2019-2022. If you are using an older version of Revit, you will need to upgrade to a more recent Revit release to use Pollination.'
  },
  {
    question: 'Do the CAD plugins come with Pollination Cloud Resources?',
    answer: 'The Rhino Plugin doesn\'t come with cloud computing credits. You must have a Pollination Starter, Business, or Organizational plan to enable cloud computing capabilities.'
  }
]

interface CADPluginDownloadActionProps {
  productKey: 'grasshopper' | 'rhino' | 'revit'
  label: string
  icon?: React.ReactNode
}

const CADPluginDownloadAction: React.FunctionComponent<CADPluginDownloadActionProps> = ({ productKey, label, icon }) => {
  const { loading, downloadPlugin } = useDownloadPlugin()

  return (
    <Button size='large' type='primary'
      loading={loading}
      onClick={() => downloadPlugin(productKey)}
    >
      {icon ? icon : <ThunderboltFilled />}
      {label}
    </Button>
  )
}

const cards: ProductCard[] = [
  {
    title: 'Grasshopper',
    description: 'Free plugin to use Pollination through your favorite modeling tool.',
    action: <CADPluginDownloadAction productKey='grasshopper' label='Download for Free' />,
    features: [
      'Run parametric studies at scale',
      'Share your workflows with control',
      'Access your simulations everywhere',
      'Do it all inside Grasshopper',
    ],
    moreInfoLink: 'https://www.pollination.cloud/grasshopper-plugin',
    productName: 'cad_plugins',
    resourceName: 'grasshopper_plugin_license',
    priceOverride: 0
  },
  {
    title: 'Rhino',
    description: 'The most flexible interface for environmental building simulation.',
    action: <CADPluginDownloadAction productKey='rhino' label='Download Free Trial' />,
    features: [
      'Keep up with the speed of design',
      'Build complex models with ease',
      'Resolve errors quickly',
      'Automate tedious parts of your process',
    ],
    moreInfoLink: 'https://www.pollination.cloud/rhino-plugin',
    productName: 'cad_plugins',
    resourceName: 'rhino_plugin_license',
    priceOverride: 10000
  },
  {
    title: 'Revit',
    description: 'BIM to BEM in a few clicks. Connect to Pollination Cloud directly.',
    action: <CADPluginDownloadAction productKey='revit' label='Download Free Trial' />,
    features: [
      'Customizable export of BIM to BEM',
      'Extract meta data for energy modeling',
      'Connect to Pollination Cloud',
      'Preview and check you BEM export',
    ],
    moreInfoLink: 'https://www.pollination.cloud/revit-plugin',
    productName: 'cad_plugins',
    resourceName: 'revit_plugin_license',
    priceOverride: 20000
  },
]

const CADPlugins = () => {

  return (
    <ProductsTemplate title={defaultTitle} cards={cards} faqs={faqs} />
  )
}


export default CADPlugins